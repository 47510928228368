/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { Link } from "@mui/material";
import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";
import { decode } from "html-entities";
import {
  AddIconOutline,
  SearchIcon,
  DeleteUpdated,
} from "../../components/SvgIcons";
import {
  DeleteExercise,
  GetExercises,
  DuplicateExercise,
  DeleteMultipleExercise,
  GetAllExercises,
  GetCompliedAllExercises,
  UpdateExercise,
} from "../../services/exercises.service";
import {
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import { toast } from "react-toastify";
import {
  DataGridPro,
  GridToolbar,
  useGridApiRef,
  GridCellEditStopReasons,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { MenuItem, Select, Tooltip, Switch } from "@mui/material";
import { HiOutlineDocumentDuplicate } from "react-icons/hi2";
import { MdEdit, MdDelete, MdSummarize } from "react-icons/md";
import {
  constants,
  cross_origin,
  REACT_APP_MONDAY_ACCESS_TOKEN,
} from "../../utils/constants";
import InputBase from "@mui/material/InputBase";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import FullPageLoader from "../../components/FullPageLoader";
import { FadeLoader } from "react-spinners";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function ListExercise(props) {
  let history = useHistory();
  const apiRef = useGridApiRef();
  const apiRef2 = useGridApiRef();
  const apiRef3 = useGridApiRef();
  const [editingCell, setEditingCell] = useState(null);
  const [key, setKey] = useState("all");
  const [columns, setColumns] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [columnVisibilityModelI, setColumnVisibilityModelI] = useState({});
  const [columnVisibilityModelC, setColumnVisibilityModelC] = useState({});
  const [withoutApiLoading, setWithoutApiLoading] = useState(false);

  // All Exercise
  const [rowsAll, setRowsAll] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [perPage, setPerPage] = useState(500);
  const [page, setPage] = useState(1);
  const [countPage, setPageCount] = useState(0);
  const [orderByValue, setOrderByValue] = useState("asc");
  const [orderByColumn, setOrderByColumn] = useState("name");
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [totalIndividualCount, setTotalIndividualCount] = useState();
  const [totalCompiledCount, setTotalCompiledCount] = useState();
  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  const CustomFooter = ({ totalRows }) => (
    <div style={{ padding: "10px", textAlign: "right" }}>
      Total Rows: {totalRows}
    </div>
  );

  // Individual Exercise
  const [keywordI, setKeywordI] = useState("");
  const [indLoading, setIndLoading] = useState(false);
  const [searchIndLoading, setSearchIndLoading] = useState(false);
  const [indPage, setIndPage] = useState(1);
  const [indPerPage, setIndPerPage] = useState(500);
  const [indCountPage, setIndPageCount] = useState(0);
  const [orderByValueI, setOrderByValueI] = useState("asc");
  const [orderByColumnI, setOrderByColumnI] = useState("name");

  // Compiled Exercise
  const [rowsCompiled, setRowsCompiled] = useState([]);
  const [keywordC, setKeywordC] = useState("");
  const [comLoading, setComLoading] = useState(false);
  const [searchComLoading, setSearchComLoading] = useState(false);
  const [comPage, setComPage] = useState(1);
  const [comPerPage, setComPerPage] = useState(500);
  const [comCountPage, setComPageCount] = useState(0);
  const [orderByValueC, setOrderByValueC] = useState("asc");
  const [orderByColumnC, setOrderByColumnC] = useState("name");

  const [isExercise, setIsExercise] = useState(true);
  const [isCompilation, setIsCompilation] = useState(true);

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handleIndPaginationChange = (event, value) => {
    setIndPage(value);
  };

  const handleComPaginationChange = (event, value) => {
    setComPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPerPage(event.target.value);
  };

  const handleIndPageSizeChange = (event) => {
    setIndPerPage(event.target.value);
  };

  const handleComPageSizeChange = (event) => {
    setComPerPage(event.target.value);
  };

  const handleClick = () => {
    history.push("/add-exercise");
  };

  const handleCompilationClick = () => {
    history.push("/add-compilation");
  };

  const getColumnsOrderChange = async (module_name) => {
    await GetColumnHideShow(module_name, "column_order_change").then(
      (result) => {
        if (result.status && result.data) {
          const parsedArray = JSON.parse(result.data.table_data);
          const dataArray = Object.values(parsedArray);
          const completeColumns = dataArray.map((item) => {
            if (item.field == "actions") {
              return {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                filterable: false,
                width: item.width,
                renderCell: (params) => (
                  <>
                    {params?.row?.isParent == 0 ? (
                      ""
                    ) : (
                      <>
                        <>
                          <div>
                            <Tooltip title="Edit" arrow>
                              <button
                                className={"btn btn-primary btn-sm"}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onEdit(params.row);
                                }}
                              >
                                <MdEdit />
                              </button>
                            </Tooltip>
                            <Tooltip title="Delete" arrow>
                              <button
                                className={"btn btn-danger btn-sm mx-2"}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onDelete(params.row);
                                }}
                              >
                                <MdDelete />
                              </button>
                            </Tooltip>
                            <Tooltip title="Summary" arrow>
                              <button
                                className={"btn btn-info  btn-sm"}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  redirectToSummary(params.row);
                                }}
                              >
                                <MdSummarize />
                              </button>
                            </Tooltip>
                            <Tooltip title="Duplicate" arrow>
                              <button
                                className={"btn btn-warning  btn-sm mx-2"}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onDuplicate(params.row);
                                }}
                              >
                                <HiOutlineDocumentDuplicate />
                              </button>
                            </Tooltip>
                          </div>
                        </>
                      </>
                    )}
                  </>
                ),
              };
            } else if (item.field == "bodytype") {
              return {
                field: "bodytype",
                headerName: "Body Type",
                width: item.width,
                renderCell: (params) => (
                  <>
                    {params?.row?.isParent == 0 ? (
                      <img
                        src={params?.value}
                        alt="Image"
                        style={{ width: "50px", height: "50px" }}
                      />
                    ) : (
                      <MultiLineCellRenderer
                        value={params.value}
                        lines={lineCount1}
                      />
                    )}
                  </>
                ),
              };
            } else if (item.field == "exerciseType") {
              return {
                field: "exerciseType",
                headerName: "Exercise Type",
                width: item.width,
                renderCell: (params) => (
                  <>
                    {params?.row?.isParent == 0 && params?.value != "" ? (
                      <Link
                        href={params.value}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {spittedName(params.value)}
                      </Link>
                    ) : (
                      <MultiLineCellRenderer
                        value={params.value}
                        lines={lineCount1}
                      />
                    )}
                  </>
                ),
              };
            } else if (item.field == "muscle") {
              return {
                field: "muscle",
                headerName: "Muscle",
                width: item.width,
                renderCell: (params) =>
                  params?.row?.isParent == 0 ? (
                    <MultiLineCellRenderer
                      value={params.value}
                      lines={lineCount1}
                    />
                  ) : (
                    // <a
                    //   href={params.value}
                    //   target="_blank"
                    //   rel="feet_it_here noreferrer"
                    // >
                    //   <img
                    //     src={params.value}
                    //     alt="Male"
                    //     style={{ width: "auto", height: 50 }}
                    //     onError={(e) => {
                    //       e.target.onerror = null;
                    //       e.target.src = constants.IMAGENOTFOUND;
                    //     }}
                    //     className={"img-table img-thumbnail"}
                    //     crossOrigin={cross_origin ? "anonymous" : null}
                    //   />
                    // </a>
                    <MultiLineCellRenderer
                      value={params.value}
                      lines={lineCount1}
                    />
                  ),
              };
            } else if (item.field == "category") {
              return {
                field: "category",
                headerName: "Sub Category",
                width: 120,
                renderCell: (params) => (
                  <>
                    {params?.row?.isParent == 0 ? (
                      <Link
                        href={params.value}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {spittedName(params.value)}
                      </Link>
                    ) : (
                      <MultiLineCellRenderer
                        value={params.value}
                        lines={params.row.lines}
                      />
                    )}
                  </>
                ),
              };
            } else {
              return item;
            }
          });
          setColumns(completeColumns);
        }
      }
    );
  };

  const getColumnsHideShow = async (moduleName, type) => {
    await GetColumnHideShow(moduleName, type).then((result) => {
      if (result?.status && result?.data && result.data.table_data !== null) {
        const tableData = JSON.parse(result?.data?.table_data);
        if (moduleName === "all_exercise") {
          setColumnVisibilityModel(tableData);
        } else if (moduleName === "individual_exercise") {
          setColumnVisibilityModelI(tableData);
        } else if (moduleName === "compiled_exercise") {
          setColumnVisibilityModelC(tableData);
        }
      }
    });
  };

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };

  useEffect(async () => {
    await getColumnsHideShow("all_exercise", "column_hide_show");
    await getColumnsHideShow("individual_exercise", "column_hide_show");
    await getColumnsHideShow("compiled_exercise", "column_hide_show");
    await getColumnsOrderChange("all_exercise");
  }, []);

  useEffect(async () => {
    Promise.all([await getColumns()])
      .then((res) => {
        getColumnsWidth("all_exercise", res[0]);
      })
      .catch((error) => {});
  }, []);

  useEffect(async () => {
    getAllExercises(true);
  }, [page, perPage, filterModel]);

  useEffect(() => {
    if (keyword) {
      getAllExercises(false);
    } else {
      getAllExercises(true);
    }
  }, [keyword, page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      await getExercises(true);
    };

    fetchData();
  }, [indPage, indPerPage]);

  useEffect(() => {
    const fetchData = async () => {
      if (keywordI) {
        await getExercises(false);
      } else {
        await getExercises(true);
      }
    };

    fetchData();
  }, [keywordI, indPage, indPerPage]);

  useEffect(async () => {
    await getExercisesComplied();
  }, [comPage, comPerPage]);

  useEffect(() => {
    if (keywordC) {
      getExercisesComplied(false);
    } else {
      getExercisesComplied(true);
    }
  }, [keywordC, comPage, comPerPage]);

  const stripHtml = (htmlString) => {
    if (!htmlString) return "";
    //return htmlString.replace(/<[^>]*>?/gm, "");
    return htmlString.replace(
      /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,
      " "
    );
  };

  const multilineColumn = {
    type: "string",
    renderEditCell: (params) => {
      const editParams = {
        ...params,
        value: stripHtml(params.value),
      };

      return <EditTextarea {...editParams} />;
    },
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };

  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);

    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "all_exercise",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange("all_exercise");
            }
          } else {
            setLoading(false); // Stop loading if there was an error
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false); // Stop loading if there was an error
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setLoading(false); // Ensure loading stops even if an unexpected error occurs
    } finally {
      setLoading(false); // Safely stop loading after the operation completes or fails
    }
  };

  const handleIndColumnOrderChange = () => {
    const newOrder = apiRef2.current.getAllColumns().map((col) => col.field);
    saveIndColumnOrderToDatabase(newOrder);
  };

  const saveIndColumnOrderToDatabase = async (newOrder) => {
    setIndLoading(true);

    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "individual_exercise",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange("individual_exercise");
            }
          } else {
            setIndLoading(false); // Stop loading if there was an error
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setIndLoading(false); // Stop loading if there was an error
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setIndLoading(false); // Ensure loading stops even if an unexpected error occurs
    } finally {
      setIndLoading(false); // Safely stop loading after the operation completes or fails
    }
  };

  const handleColumnWidthChange1 = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "all_exercise",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const handleColumnWidthChange2 = async (newColumns) => {
    setIndLoading(true);
    try {
      const requestObj = {
        module_name: "individual_exercise",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        setIndLoading(false);
        toast.error(result.message);
      }
    } catch (error) {
      setIndLoading(false);
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setIndLoading(false);
    }
  };

  const handleComColumnOrderChange = () => {
    const newOrder = apiRef3.current.getAllColumns().map((col) => col.field);
    saveComColumnOrderToDatabase(newOrder);
  };

  const saveComColumnOrderToDatabase = async (newOrder) => {
    setComLoading(true); // Start loading before the operation begins

    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "complied_exercise",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange("complied_exercise");
            }
          } else {
            setComLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setComLoading(false);
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setComLoading(false);
    } finally {
      setComLoading(false);
    }
  };

  const handleColumnWidthChange3 = async (newColumns) => {
    setComLoading(true);
    try {
      const requestObj = {
        module_name: "complied_exercise",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          setComLoading(false);
          console.log("no columns are found.");
        }
      } else {
        setComLoading(false);
        toast.error(result.message);
      }
    } catch (error) {
      setComLoading(false);
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setComLoading(false);
    }
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "all_exercise",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("all_exercise", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const toggleIndividualFunc = async (newModel) => {
    setIndLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "individual_exercise",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("individual_exercise", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setIndLoading(false);
    }
  };

  const toggleCompiledFunc = async (newModel) => {
    setComLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "compiled_exercise",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("compiled_exercise", "column_hide_show");
        }
      } else {
        setComLoading(false);
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setComLoading(false);
    }
  };

  function isKeyboardEvent(event) {
    return !!event.key;
  }

  function EditTextarea(props) {
    const { id, field, value, colDef, hasFocus } = props;
    const [valueState, setValueState] = React.useState(value);
    const [anchorEl, setAnchorEl] = React.useState();
    const [inputRef, setInputRef] = React.useState(null);
    const apiRefTextArea = useGridApiContext();

    useEffect(() => {
      if (hasFocus && inputRef) {
        inputRef.focus();
      }
    }, [hasFocus, inputRef]);

    const handleRef = React.useCallback((el) => {
      setAnchorEl(el);
    }, []);

    const handleChange = React.useCallback(
      (event) => {
        const newValue = event.target.value;
        setValueState(newValue);
        apiRefTextArea.current.setEditCellValue(
          { id, field, value: newValue, debounceMs: 200 },
          event
        );
      },
      [apiRefTextArea, field, id]
    );

    return (
      <div style={{ position: "relative", alignSelf: "flex-start" }}>
        <div
          ref={handleRef}
          style={{
            height: 1,
            width: colDef.computedWidth,
            display: "block",
            position: "absolute",
            top: 0,
          }}
        />
        {anchorEl && (
          <Popper open anchorEl={anchorEl} placement="bottom-start">
            <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
              <InputBase
                multiline
                rows={4}
                value={valueState}
                sx={{ textarea: { resize: "both" }, width: "100%" }}
                onChange={handleChange}
                inputRef={(ref) => setInputRef(ref)}
              />
            </Paper>
          </Popper>
        )}
      </div>
    );
  }

  const MultiLineCellRenderer = ({ value, lines }) => {
    return (
      <div
        style={{
          whiteSpace: "pre-line",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: lines,
          WebkitBoxOrient: "vertical",
        }}
      >
        {value}
      </div>
    );
  };

  const getColumns = async () => {
    const obj = [
      {
        field: "name",
        headerName: "Exercise Name",
        width: 300,
        editable: true,
        resizable: true,
        renderCell: (params) => (
          <MultiLineCellRenderer
            value={params.value}
            lines={params.row.lines}
          />
        ),
      },
      {
        field: "level",
        headerName: "Level",
        width: 50,
      },
      {
        field: "bodytype",
        headerName: "Body Type",
        width: 120,
        renderCell: (params) => (
          <>
            {params?.row?.isParent == 0 ? (
              <img
                src={params?.value}
                alt="Image"
                style={{ width: "50px", height: "50px" }}
              />
            ) : (
              <MultiLineCellRenderer
                value={params.value}
                lines={params.row.lines}
              />
            )}
          </>
        ),
      },
      {
        field: "category",
        headerName: "Sub Category",
        width: 120,
        renderCell: (params) => (
          <>
            {params?.row?.isParent == 0 ? (
              <Link
                href={params.value}
                target="_blank"
                rel="noopener noreferrer"
              >
                {spittedName(params.value)}
              </Link>
            ) : (
              <MultiLineCellRenderer
                value={params.value}
                lines={params.row.lines}
              />
            )}
          </>
        ),
      },
      {
        field: "exerciseType",
        headerName: "Exercise Type",
        width: 120,
        renderCell: (params) => (
          <>
            {params?.row?.isParent == 0 && params?.value != "" ? (
              <Link
                href={params.value}
                target="_blank"
                rel="noopener noreferrer"
              >
                {spittedName(params.value)}
              </Link>
            ) : (
              <MultiLineCellRenderer
                value={params.value}
                lines={params.row.lines}
              />
            )}
          </>
        ),
      },
      {
        field: "muscle",
        headerName: "Muscle",
        width: 140,
        renderCell: (params) =>
          params?.row?.isParent == 0 ? (
            <MultiLineCellRenderer value={params.value} lines={lineCount1} />
          ) : (
            // <a
            //   href={params.value}
            //   target="_blank"
            //   rel="feet_it_here noreferrer"
            // >
            //   <img
            //     src={params.value}
            //     alt="Male"
            //     style={{ width: "auto", height: 50 }}
            //     onError={(e) => {
            //       e.target.onerror = null;
            //       e.target.src = constants.IMAGENOTFOUND;
            //     }}
            //     className={"img-table img-thumbnail"}
            //     crossOrigin={cross_origin ? "anonymous" : null}
            //   />
            // </a>
            <MultiLineCellRenderer value={params.value} lines={lineCount1} />
          ),
      },
      {
        field: "equipments",
        headerName: "Equipment",
        width: 150,
        renderCell: (params) => (
          <>
            <MultiLineCellRenderer value={params.value} lines={lineCount1} />
          </>
        ),
      },
      {
        field: "bodyPartName",
        headerName: "Feet it here",
        width: 150,
        renderCell: (params) => (
          <>
            <MultiLineCellRenderer value={params.value} lines={lineCount1} />
          </>
        ),
      },

      {
        field: "internal_notes",
        headerName: "Internal Notes",
        width: 140,
        editable: true,
        ...multilineColumn,
        renderCell: (params) => {
          const sanitizedValue = stripHtml(params.value);

          return (
            <>
              {params?.row?.isParent == 0 ? (
                <label>{sanitizedValue}</label>
              ) : (
                <MultiLineCellRenderer
                  value={params.value}
                  lines={params.row.lines}
                />
              )}
            </>
          );
        },
      },
      {
        field: "weight_scale",
        headerName: "Weight",
        width: 120,
        renderCell: (params) => {
          const [isActive, setIsActive] = useState(params.value);

          const handleToggle = async () => {
            setIsActive(!isActive);
            const newIsActive = !isActive;

            let id = params.id.replace(/^(C-|I-)/, "");
            let dt = {
              weight_scale: newIsActive,
              name: params.row.originalName,
            };
            await UpdateExercise(id, dt)
              .then(async (data) => {
                console.log("data", data);
                console.log("newIsActive", newIsActive);
                let weightText = newIsActive
                  ? "Heavy (heavier weights)"
                  : "Precision (lower weights)";

                var query1 = `mutation {
                  change_simple_column_value (
                  board_id: 7278133312,
                  item_id: ${params.row.pulseId},
                  column_id: "text66__1",
                  value: "${weightText}"
                  ) {
                        id
                        name
                        column_values (ids: ["text66__1"]) {
                          id
                          text
                        }
                      }
                    }`;
                let res = await fetch("https://api.monday.com/v2", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: REACT_APP_MONDAY_ACCESS_TOKEN,
                  },
                  body: JSON.stringify({
                    query: query1,
                  }),
                });
              })
              .catch((error) => {});
          };
          return params.row.exerciseType === "Exercise" ? (
            <>
              <Switch
                checked={isActive}
                onChange={handleToggle}
                color="default"
                sx={{
                  "& .MuiSwitch-thumb": {
                    backgroundColor: isActive ? "black" : "white",
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: isActive ? "gray" : "lightgray",
                  },
                }}
              />
            </>
          ) : null;
        },
      },
      {
        field: "isComplete",
        headerName: "Complete",
        width: 120,
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        filterable: false,
        width: 180,
        renderCell: (params) => (
          <>
            {params?.row?.isParent == 0 ? (
              ""
            ) : (
              <>
                <>
                  <div>
                    <Tooltip title="Edit" arrow>
                      <button
                        className={"btn btn-primary btn-sm"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onEdit(params.row);
                        }}
                      >
                        <MdEdit />
                      </button>
                    </Tooltip>
                    <Tooltip title="Delete" arrow>
                      <button
                        className={"btn btn-danger btn-sm mx-2"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onDelete(params.row);
                        }}
                      >
                        <MdDelete />
                      </button>
                    </Tooltip>
                    <Tooltip title="Summary" arrow>
                      <button
                        className={"btn btn-info  btn-sm"}
                        onClick={(event) => {
                          event.stopPropagation();
                          redirectToSummary(params.row);
                        }}
                      >
                        <MdSummarize />
                      </button>
                    </Tooltip>
                    <Tooltip title="Duplicate" arrow>
                      <button
                        className={"btn btn-warning  btn-sm mx-2"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onDuplicate(params.row);
                        }}
                      >
                        <HiOutlineDocumentDuplicate />
                      </button>
                    </Tooltip>
                  </div>
                </>
              </>
            )}
          </>
        ),
      },
    ];

    return obj;
  };

  const getAllExercises = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }

    await GetAllExercises(
      keyword,
      page,
      perPage,
      1,
      orderByValue,
      orderByColumn,
      // 1,
      filterModel.items
    )
      .then((result) => {
        if (result.status) {
          if (result.data) {
            const rowData = [];
            setTotalCount(result?.data?.meta?.total);
            setPage(result.data?.meta?.current_page);
            setPageCount(result.data?.meta?.last_page);
            result?.data?.data?.forEach((dt) => {
              console.log("dt", dt);

              let level = dt?.level_id == 4 ? "0" : dt?.level_id;
              const min = 1;
              const max = 10000;
              const rand = min + Math.random() * (max - min);
              const bodyPartName =
                dt.exerciseLevels?.[0]?.body_part?.name || "-";
              rowData.push({
                pulseId: dt?.pulse_id,
                level: "L" + level,
                id: dt?.is_compilation === 1 ? `C-${dt?.id}` : `I-${dt?.id}`,
                name: dt?.name,
                originalName: dt?.name,
                exerciseType: dt?.exerciseTypesCSV ? dt?.exerciseTypesCSV : "-",
                bodytype: dt?.exerciseBodyTypesCSV
                  ? dt.exerciseBodyTypesCSV
                  : "-",
                muscle: dt?.exerciseMusclesCSV ? dt?.exerciseMusclesCSV : "-",
                category: dt?.exerciseCategoriesCSV
                  ? dt?.exerciseCategoriesCSV
                  : "-",
                equipments: dt?.exerciseEquipmentUsed
                  ? dt?.exerciseEquipmentUsed
                  : "-",
                isComplete: dt?.is_complete
                  ? dt?.is_complete == 1
                    ? "Complete"
                    : "Incomplete"
                  : "Incomplete",
                exerciseLevels: dt?.exerciseLevels,
                isCompilation:
                  dt?.is_compilation == 1 ? "Compiled" : "Individual",
                weight_scale: dt?.weight_scale,
                path: [
                  dt?.is_compilation === 1 ? `C-${dt?.id}` : `I-${dt?.id}`,
                ],
                isParent: 1,
                internal_notes:
                  dt?.internal_notes != null
                    ? stripHtml(dt?.internal_notes)
                    : "-",

                lines: 1,
                bodyPartName: bodyPartName,
              });
              if (dt.exerciseLevels && dt.exerciseLevels.length > 0) {
                rowData.push({
                  id: rand,
                  name: "Exercise Name",
                  bodytype: "Cover photo",
                  category: "Rep Video Link",
                  exerciseType: "Ed Video Link",
                  muscle: "Feel It Here",
                  internal_notes: "Internal Notes",
                  path: [
                    dt?.is_compilation === 1 ? `C-${dt?.id}` : `I-${dt?.id}`,
                    rand,
                  ],
                  isParent: 1,
                  isHeader: 1,
                });
                dt.exerciseLevels.forEach((level) => {
                  rowData.push({
                    id: level?.id,
                    name: level?.file_name,
                    bodytype: level?.video_cover,
                    category: level?.video,
                    exerciseType: level?.exerciseEducationVideo[0]
                      ? level?.exerciseEducationVideo[0]?.video
                      : "",
                    muscle: level?.body_part?.name,
                    internal_notes:
                      level?.internal_notes != null
                        ? stripHtml(level?.internal_notes)
                        : "-",

                    path: [
                      dt?.is_compilation === 1 ? `C-${dt?.id}` : `I-${dt?.id}`,
                      level?.id,
                    ],
                    isParent: 0,
                    isHeader: 0,
                  });
                });
              }
            });
            setRowsAll(rowData);
          }
        }
        if (isInitialLoad) {
          setLoading(false);
        } else {
          setSearchLoading(false);
        }
      })
      .catch((error) => {
        if (isInitialLoad) {
          setLoading(false);
        } else {
          setSearchLoading(false);
        }
      });
  };

  const getExercises = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setIndLoading(true);
    } else {
      setSearchIndLoading(true);
    }
    try {
      const result = await GetExercises(
        keywordI,
        indPage,
        indPerPage,
        1,
        orderByValueI,
        orderByColumnI,
        0
      );

      if (result.status && result.data) {
        const rowData = [];

        setIndPage(result.data?.meta?.current_page);
        setIndPageCount(result.data?.meta?.last_page);
        setTotalIndividualCount(result.data?.meta?.total);
        result?.data?.data?.map((dt) => {
          let level = dt?.level_id == 4 ? "0" : dt?.level_id;
          const min = 1;
          const max = 10000;
          const rand = min + Math.random() * (max - min);
          const bodyPartName = dt.exerciseLevels?.[0]?.body_part?.name || "-";
          rowData.push({
            id: "I-" + dt?.id,
            name: dt?.name,
            level: "L" + level,
            exerciseType: dt?.exerciseTypesCSV ? dt?.exerciseTypesCSV : "-",
            bodytype: dt?.exerciseBodyTypesCSV ? dt?.exerciseBodyTypesCSV : "-",
            muscle: dt?.exerciseMusclesCSV ? dt?.exerciseMusclesCSV : "-",
            category: dt?.exerciseCategoriesCSV
              ? dt?.exerciseCategoriesCSV
              : "-",
            equipments: dt?.exerciseEquipmentUsed
              ? dt?.exerciseEquipmentUsed
              : "-",
            isComplete: dt?.is_complete
              ? dt?.is_complete == 1
                ? "Complete"
                : "Incomplete"
              : "Incomplete",
            exerciseLevels: dt?.exerciseLevels,
            isCompilation: dt?.is_compilation == 1 ? "Compiled" : "Individual",
            path: ["I-" + dt?.id],
            isParent: 1,
            internal_notes:
              dt?.internal_notes != null ? stripHtml(dt?.internal_notes) : "-",
            lines: 1,
            bodyPartName: bodyPartName,
          });
          if (dt?.exerciseLevels && dt?.exerciseLevels.length > 0) {
            rowData.push({
              id: rand,
              name: "Exercise Name",
              bodytype: "Cover photo",
              category: "Rep Video Link",
              exerciseType: "Ed Video Link",
              muscle: "Feel It Here",
              internal_notes: "Internal Notes",
              path: ["I-" + dt?.id, rand],
              isParent: 1,
              isHeader: 1,
            });
            dt.exerciseLevels.map((level) => {
              rowData.push({
                id: level?.id,
                name: level?.file_name,
                bodytype: level?.video_cover,
                category: level?.video,
                exerciseType: level?.exerciseEducationVideo[0]
                  ? level?.exerciseEducationVideo[0]?.video
                  : "",
                muscle: level?.body_part?.name,
                internal_notes:
                  level?.internal_notes != null
                    ? stripHtml(level?.internal_notes)
                    : "-",

                path: ["I-" + dt?.id, level?.id],
                isParent: 0,
                isHeader: 0,
              });
            });
          }
        });
        setRows(rowData);
        if (isInitialLoad) {
          setIndLoading(false);
        } else {
          setSearchIndLoading(false);
        }
      }
    } catch (error) {
      if (isInitialLoad) {
        setIndLoading(false);
      } else {
        setSearchIndLoading(false);
      }
    }
  };

  const getExercisesComplied = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setComLoading(true);
    } else {
      setSearchComLoading(true); // This line seems incorrect
    }
    await GetCompliedAllExercises(
      keywordC,
      comPage,
      comPerPage,
      1,
      orderByValueC,
      orderByColumnC,
      0
    )
      .then((result) => {
        if (result.status) {
          if (result.data) {
            const rowDataCompiled = [];
            setComPage(result.data?.meta?.current_page);
            setComPageCount(result.data?.meta?.last_page);
            setTotalCompiledCount(result.data?.meta?.total);
            result?.data?.data?.map((dt) => {
              let level = dt?.level_id == 4 ? "0" : dt?.level_id;
              const bodyPartName =
                dt.exerciseLevels?.[0]?.body_part?.name || "-";
              rowDataCompiled.push({
                id: dt?.is_compilation === 1 ? `C-${dt?.id}` : `I-${dt?.id}`,
                name: dt?.name,
                level: "L" + level,
                exerciseType:
                  dt?.exerciseTypes.length > 0
                    ? dt?.exerciseTypes[0]?.name
                    : "-",
                bodytype:
                  dt?.exerciseBodyTypes.length > 0
                    ? dt?.exerciseBodyTypes[0]?.name
                    : "-",
                muscle:
                  dt?.exerciseMuscles.length > 0
                    ? dt?.exerciseMuscles[0]?.name
                    : "-",
                category:
                  dt?.exerciseCategories.length > 0
                    ? dt?.exerciseCategories[0]?.name
                    : "-",
                equipments: dt?.exerciseEquipmentUsed
                  ? dt?.exerciseEquipmentUsed
                  : "-",
                isComplete: dt?.is_complete
                  ? dt?.is_complete == 1
                    ? "Complete"
                    : "Incomplete"
                  : "Incomplete",
                path: [
                  dt?.is_compilation === 1 ? `C-${dt?.id}` : `I-${dt?.id}`,
                ],
                isCompilation:
                  dt?.is_compilation == 1 ? "Compiled" : "Individual",
                internal_notes:
                  dt?.internal_notes != null
                    ? stripHtml(dt?.internal_notes)
                    : "-",
                lines: 1,
                bodyPartName: bodyPartName,
              });
              if (dt.compliationVideos && dt.compliationVideos.length > 0) {
                const randHeader = generateUniqueId();
                rowDataCompiled.push({
                  id: randHeader,
                  name: "Exercise Name",
                  level: "Level",
                  bodytype: "Cover photo",
                  category: "Rep Video Link",
                  exerciseType: "Ed Video Link",
                  muscle: "Feel It Here",
                  internal_notes: "Internal Notes",
                  path: [
                    dt?.is_compilation === 1 ? `C-${dt?.id}` : `I-${dt?.id}`,
                    randHeader,
                  ],
                  isParent: 1,
                  isHeader: 1,
                });

                dt.compliationVideos.map((level) => {
                  level?.exerciseLevels.map((exerciseLevel) => {
                    const rand = generateUniqueId();
                    rowDataCompiled.push({
                      id: rand,
                      name: exerciseLevel?.file_name,
                      level: "",
                      bodytype: exerciseLevel?.video_cover,
                      category: exerciseLevel?.video,
                      exerciseType: exerciseLevel?.exerciseEducationVideo[0]
                        ? exerciseLevel?.exerciseEducationVideo[0]?.video
                        : "",
                      muscle: level?.body_part?.name,
                      internal_notes:
                        exerciseLevel?.internal_notes != null
                          ? stripHtml(exerciseLevel?.internal_notes)
                          : "-",

                      path: [
                        dt?.is_compilation === 1
                          ? `C-${dt?.id}`
                          : `I-${dt?.id}`,
                        rand,
                      ],
                      isParent: 0,
                      isHeader: 0,
                    });
                  });
                });
              }
            });
            setRowsCompiled(rowDataCompiled);
            if (isInitialLoad) {
              setComLoading(false);
            } else {
              setSearchComLoading(false); // This line seems incorrect
            }
          }
        }
      })
      .catch((error) => {
        if (isInitialLoad) {
          setComLoading(false);
        } else {
          setSearchComLoading(false);
        }
      });
  };

  const generateUniqueId = () => {
    return Math.floor(Math.random() * 1000000);
  };

  const transhRedirect = (data) => {
    history.push("/trash-data/");
  };

  const individualTranshRedirect = (data) => {
    history.push("/individual-trash-data/");
  };
  const compiledtranshRedirect = (data) => {
    history.push("/compiled-trash-data/");
  };

  const onDelete = (data) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Exercise API
      if (willShip) {
        let id = data.id.replace(/^(C-|I-)/, "");

        await DeleteExercise(id)
          .then(async (result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              if (key == "all") {
                getAllExercises();
              } else if (key == "individual") {
                getExercises();
              } else if (key == "compiled") {
                getExercisesComplied();
              }
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const onDuplicate = (exercise) => {
    swal({
      title: "Are you sure?",
      text: "You want to duplicate this exercise?",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter exercise name",
          className: "form-control",
          required: true,
        },
      },
      buttons: {
        close: {
          text: "Cancel",
          value: { button: "cancel" },
        },
        confirm: {
          text: "Confirm",
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then(async (willShip) => {
      if (willShip?.button !== "cancel") {
        if (willShip) {
          const data = {
            name: willShip,
          };
          await DuplicateExercise(exercise.id, data)
            .then((result) => {
              if (result.status) {
                history.push(
                  "/edit-exercise/" + result.data.id,
                  JSON.stringify({
                    data: data,
                  })
                );
              } else {
                toast.error(result.message);
              }
            })
            .catch((error) => {
              toast.error(error.response.data.message.replace(/_/g, " "));
            });
        } else {
          swal("Warning", "Exercise name is required!", "warning");
        }
      }
    });
  };

  const redirectToSummary = (data) => {
    let id = data.id;
    id = id.replace(/^(C-|I-)/, "");
    if (data.isCompilation == "Individual") {
      history.push({
        pathname: "/summary-excercise/" + id,
        state: {
          exercise: data,
          redirectToSummary: true,
        },
      });
    } else {
      history.push({
        pathname: "/summary-excercise-compliation/" + id,
        state: {
          exercise: data,
          redirectToSummary: true,
        },
      });
    }
  };

  const spittedName = (url) => {
    if (url != "") {
      const urlSegments = url.split("/");
      const videoNameWithExtension = urlSegments[urlSegments.length - 1];
      return videoNameWithExtension;
    }
    return "";
  };
  const tabChange = async (key) => {
    setWithoutApiLoading(true);
    setKey(key);

    if (key === "individual") {
      setIsCompilation(false);
      setIsExercise(true);
    } else if (key === "compiled") {
      setIsExercise(false);
      setIsCompilation(true);
    } else if (key === "all") {
      setIsCompilation(true);
      setIsExercise(true);
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));

    setWithoutApiLoading(false);
  };

  const processRowUpdate = async (newRow, oldRow) => {
    let dt = {
      name: newRow?.name,
      internal_notes:
        newRow?.internal_notes !== "-" ? newRow?.internal_notes : "",
    };

    await UpdateExercise(newRow?.id, dt)
      .then(async (data) => {
        if (key === "individual") {
          await getExercises();
        } else if (key === "all") {
          await getAllExercises();
        } else if (key === "compiled") {
          await getExercisesComplied();
        }
      })
      .catch((error) => {});

    return newRow;
  };

  const handleCellEditStop = (params, event) => {
    if (
      params.reason === GridCellEditStopReasons.enterKeyDown ||
      params.reason === GridCellEditStopReasons.cellFocusOut
    ) {
      if (isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) {
        event.defaultMuiPrevented = true;
      }
    }

    // Clear the editing cell state when editing stops
    setEditingCell(null);
  };

  const handleCellEditStart = (params) => {
    if (
      editingCell &&
      (editingCell.id !== params.id || editingCell.field !== params.field)
    ) {
      apiRef.current.stopCellEditMode({
        id: editingCell.id,
        field: editingCell.field,
      });
    }

    // Set the new editing cell
    setEditingCell({ id: params.id, field: params.field });
  };

  const handleCellEditCommit = (params) => {
    apiRef.current.stopCellEditMode({
      id: params.id,
      field: params.field,
    });
  };

  const onEdit = (data) => {
    let id = data.id.replace(/^(C-|I-)/, "");
    history.push(
      "/edit-exercise/" + id,
      JSON.stringify({
        data: data,
      })
    );
  };

  const onMultipleDelete = async () => {
    let rowsDeleted;
    if (key == "all") {
      rowsDeleted = await apiRef.current.getSelectedRows();
    } else if (key == "individual") {
      rowsDeleted = await apiRef2.current.getSelectedRows();
    } else if (key == "compiled") {
      rowsDeleted = await apiRef3.current.getSelectedRows();
    }

    const dataArray = [...rowsDeleted.values()];

    const dataArrayIds = dataArray.map((li) => li.id);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removeExercises: dataArrayIds,
      };
      if (willShip) {
        await DeleteMultipleExercise(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getAllExercises();
              getExercises();
              getExercisesComplied();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const handleEditCellChange = async (params) => {
    const { id, field, value } = params;

    setRows((prevRows) => {
      return prevRows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
    });
  };

  const [lineCount1, setLineCount1] = useState(1);

  const handleLineCountChange1 = (event) => {
    const newLineCount = parseInt(event.target.value);
    setLineCount1(newLineCount);
    const tempRowsAll = rowsAll;
    const updatedRowsAll = tempRowsAll.map((row) => ({
      ...row,
      lines: newLineCount,
    }));
    setRowsAll(updatedRowsAll);

    const tempRows = rows;
    const updatedRows = tempRows.map((row) => ({
      ...row,
      lines: newLineCount,
    }));
    setRows(updatedRows);

    const tempRowsCompiled = rowsCompiled;
    const updatedRowsCompiled = tempRowsCompiled.map((row) => ({
      ...row,
      lines: newLineCount,
    }));
    setRowsCompiled(updatedRowsCompiled);
  };

  const handleFilterModelChange = (newFilterModel) => {
    console.log("Filter model changed:", newFilterModel);
    setFilterModel(newFilterModel);
  };

  const handleBackBtn = () => {
    history.push("/dashboard");
  };

  return (
    <div className={"orderPage exercise-menagement"}>
      <div className="page-title">
        <h1 className="page-heading">Exercise Management</h1>

        <Button onClick={handleBackBtn} className={"common-btn"}>
          Back
        </Button>
      </div>
      <div className="exercise-section">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="home-tab"
              onClick={() => tabChange("all")}
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              All Exercises
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="profile-tab"
              onClick={() => tabChange("individual")}
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Individual Exercise
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="contact-tab"
              onClick={() => tabChange("compiled")}
              data-bs-toggle="tab"
              data-bs-target="#contact"
              type="button"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
            >
              Compiled Exercise
            </button>
          </li>
        </ul>
        <div className="common-add">
          <div className="all-user">
            <div className="search-bar">
              <div className="common-search-bar">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">
                    <SearchIcon />
                  </InputGroup.Text>
                  {key == "all" ? (
                    <FormControl
                      placeholder="Search all exercises"
                      aria-label="Search"
                      aria-describedby="Search"
                      onChange={(e) => {
                        e.target.value.length > 0
                          ? setKeyword(e.target.value.trim())
                          : setKeyword(null);
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {key == "individual" ? (
                    <FormControl
                      placeholder="Search individual exercises"
                      aria-label="Search"
                      aria-describedby="Search"
                      onChange={(e) => {
                        e.target.value.length > 0
                          ? setKeywordI(e.target.value.trim())
                          : setKeywordI(null);
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {key == "compiled" ? (
                    <FormControl
                      placeholder="Search compiled exercises"
                      aria-label="Search"
                      aria-describedby="Search"
                      onChange={(e) => {
                        e.target.value.length > 0
                          ? setKeywordC(e.target.value.trim())
                          : setKeywordC(null);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </InputGroup>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center ms-3">
            {isExercise ? (
              <Button
                onClick={handleClick}
                className={"common-btn diff-btn-custom"}
              >
                <AddIconOutline /> Add Exercise
              </Button>
            ) : (
              ""
            )}

            {isCompilation ? (
              <Button
                onClick={handleCompilationClick}
                className={"common-btn diff-btn-custom"}
              >
                <AddIconOutline /> Add Compilation
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {withoutApiLoading ? (
        <div className="spin-loader">
          <FadeLoader color={"#c00000"} height={15} />
        </div>
      ) : (
        <div class="tab-content" id="myTabContent">
          <div
            className={`tab-pane fade ${key == "all" ? "show active" : ""}`}
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {key == "all" ? (
              <section className="common-table mt-2">
                <div className="user-detail">
                  <div class="delete-icon">
                    <Button
                      className={"btn common-btn"}
                      onClick={onMultipleDelete}
                    >
                      <DeleteUpdated />
                    </Button>
                  </div>

                  {loading && <FullPageLoader loading={loading} />}

                  <div style={{ height: "99vh", width: "100%" }}>
                    <StyledDataGrid
                      loading={searchLoading}
                      treeData={true}
                      grouping
                      rows={rowsAll}
                      isColumnResizable
                      onColumnWidthChange={handleColumnWidthChange1}
                      columns={columns}
                      pageSize={perPage}
                      onPageChange={(newPage) => setPage(newPage)}
                      onPageSizeChange={(newPageSize) => {
                        setPerPage(newPageSize);
                        setPage(1);
                      }}
                      checkboxSelection
                      paginationMode="server"
                      onFilterModelChange={handleFilterModelChange}
                      disableSelectionOnClick
                      pagination={false}
                      unstable_headerFilters
                      getTreeDataPath={(row) => row.path}
                      getRowClassName={(params) => {
                        const isChildRowToHide = params.row.isHeader === 1;
                        return isChildRowToHide ? "hidden-row" : "";
                      }}
                      onCellEditStart={handleCellEditStart}
                      onCellEditStop={handleCellEditStop}
                      onCellEditCommit={handleCellEditCommit}
                      slots={{
                        toolbar: GridToolbar,
                        footer: () => <CustomFooter totalRows={totalCount} />,
                      }}
                      processRowUpdate={processRowUpdate}
                      experimentalFeatures={{ newEditingApi: true }}
                      columnVisibilityModel={columnVisibilityModel}
                      onColumnVisibilityModelChange={toggleCustomFunc}
                      apiRef={apiRef}
                      initialState={{ pinnedColumns: { right: ["actions"] } }}
                      density={"compact"}
                      onColumnOrderChange={handleColumnOrderChange}
                      groupingColDef={{
                        width: 150,
                        headerAlign: "center",
                        headerName: "#",
                        sortable: true,
                        filterable: true,
                      }}
                    />
                  </div>
                </div>
                <div className="theme-dataGrid products-dataGrid">
                  <div className="user-pagination">
                    <Row>
                      <Col md={4}>
                        {" "}
                        <Button
                          className={"btn common-btn   w-35"}
                          onClick={() => transhRedirect()}
                        >
                          Trashed Exercises
                        </Button>
                      </Col>
                      <Col md={4}>
                        {" "}
                        <Stack spacing={2} alignItems="center">
                          <Pagination
                            count={countPage}
                            page={page}
                            onChange={handlePaginationChange}
                            size="large"
                          />
                        </Stack>
                      </Col>
                      <Col md={4}>
                        {" "}
                        <Stack direction="row" spacing={2} alignItems="center">
                          <label>Rows per page:</label>
                          <Select
                            value={perPage}
                            onChange={handlePageSizeChange}
                          >
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={500}>500</MenuItem>
                          </Select>
                        </Stack>
                      </Col>
                    </Row>
                  </div>
                </div>
              </section>
            ) : (
              ""
            )}
          </div>

          <div
            className={`tab-pane fade ${
              key == "individual" ? "show active" : ""
            }`}
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            {key == "individual" ? (
              <section className="common-table mt-2">
                <div className="user-detail">
                  <div class="delete-icon">
                    <Button
                      className={"btn common-btn"}
                      onClick={onMultipleDelete}
                    >
                      <DeleteUpdated />
                    </Button>
                  </div>
                  {indLoading && <FullPageLoader loading={indLoading} />}
                  <div style={{ height: "99vh", width: "100%" }}>
                    <StyledDataGrid
                      treeData={true}
                      loading={searchIndLoading}
                      grouping
                      rows={rows}
                      columns={columns}
                      onColumnWidthChange={handleColumnWidthChange2}
                      pageSize={indPerPage}
                      onPageChange={(newPage) => setPage(newPage)}
                      onPageSizeChange={(newPageSize) => {
                        setIndPerPage(newPageSize);
                        setIndPage(1);
                      }}
                      checkboxSelection
                      paginationMode="server"
                      disableSelectionOnClick
                      pagination={false}
                      unstable_headerFilters
                      getTreeDataPath={(row) => row.path}
                      onCellEditStart={handleCellEditStart}
                      onCellEditStop={handleCellEditStop}
                      onCellEditCommit={handleCellEditCommit}
                      onEditCellChange={handleEditCellChange}
                      getRowClassName={(params) => {
                        const isChildRowToHide = params.row.isHeader === 1;
                        return isChildRowToHide ? "hidden-row" : "";
                      }}
                      slots={{
                        toolbar: GridToolbar,
                        footer: () => (
                          <CustomFooter totalRows={totalIndividualCount} />
                        ),
                      }}
                      processRowUpdate={processRowUpdate}
                      experimentalFeatures={{ newEditingApi: true }}
                      apiRef={apiRef2}
                      columnVisibilityModel={columnVisibilityModelI}
                      onColumnVisibilityModelChange={toggleIndividualFunc}
                      density={"compact"}
                      initialState={{ pinnedColumns: { right: ["actions"] } }}
                      groupingColDef={{
                        width: 150,
                        headerAlign: "center",
                        headerName: "#",
                        sortable: true,
                        filterable: true,
                      }}
                      onColumnOrderChange={handleIndColumnOrderChange}
                    />
                  </div>
                </div>
                <div className="theme-dataGrid products-dataGrid">
                  <div className="user-pagination">
                    <Row>
                      <Col md={4}>
                        {" "}
                        <Button
                          className={"btn common-btn   w-35"}
                          onClick={() => individualTranshRedirect()}
                        >
                          Trashed Exercises
                        </Button>
                      </Col>
                      <Col md={4}>
                        {" "}
                        <Stack spacing={2}>
                          <Pagination
                            count={indCountPage}
                            page={indPage}
                            onChange={handleIndPaginationChange}
                            size="large"
                          />
                        </Stack>
                      </Col>
                      <Col md={4}>
                        {" "}
                        <Stack direction="row" spacing={2} alignItems="center">
                          <label>Rows per page:</label>
                          <Select
                            value={indPerPage}
                            onChange={handleIndPageSizeChange}
                          >
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={500}>500</MenuItem>
                          </Select>
                        </Stack>
                      </Col>
                    </Row>
                  </div>
                </div>
              </section>
            ) : (
              ""
            )}
          </div>

          <div
            className={`tab-pane fade ${
              key == "compiled" ? "show active" : ""
            }`}
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            {key == "compiled" ? (
              <section className="common-table mt-2">
                <div className="user-detail">
                  <div class="delete-icon">
                    <Button
                      className={"btn common-btn"}
                      onClick={onMultipleDelete}
                    >
                      <DeleteUpdated />
                    </Button>
                  </div>
                  {comLoading && <FullPageLoader loading={comLoading} />}
                  <div style={{ height: "99vh", width: "100%" }}>
                    <StyledDataGrid
                      loading={searchComLoading}
                      treeData={true}
                      grouping
                      rows={rowsCompiled}
                      columns={columns}
                      onColumnWidthChange={handleColumnWidthChange3}
                      onPageChange={(newPage) => setPage(newPage)}
                      checkboxSelection
                      paginationMode="server"
                      disableSelectionOnClick
                      pagination={false}
                      unstable_headerFilters
                      pageSize={comPerPage}
                      onPageSizeChange={(newPageSize) => {
                        setIndPerPage(newPageSize);
                        setIndPage(1);
                      }}
                      getTreeDataPath={(row) => row.path}
                      getRowClassName={(params) => {
                        const isChildRowToHide = params.row.isHeader === 1;
                        return isChildRowToHide ? "hidden-row" : "";
                      }}
                      onCellEditStart={handleCellEditStart}
                      onCellEditStop={handleCellEditStop}
                      onCellEditCommit={handleCellEditCommit}
                      slots={{
                        toolbar: GridToolbar,
                        footer: () => (
                          <CustomFooter totalRows={totalCompiledCount} />
                        ),
                      }}
                      processRowUpdate={processRowUpdate}
                      experimentalFeatures={{ newEditingApi: true }}
                      columnVisibilityModel={columnVisibilityModelC}
                      onColumnVisibilityModelChange={toggleCompiledFunc}
                      onColumnOrderChange={handleComColumnOrderChange}
                      apiRef={apiRef3}
                      density={"compact"}
                      initialState={{ pinnedColumns: { right: ["actions"] } }}
                      groupingColDef={{
                        width: 150,
                        headerAlign: "center",
                        headerName: "#",
                        sortable: true,
                        filterable: true,
                      }}
                    />
                  </div>
                </div>
                <div className="theme-dataGrid products-dataGrid">
                  <div className="user-pagination">
                    <Row>
                      <Col md={4}>
                        {" "}
                        <Button
                          className={"btn common-btn   w-35"}
                          onClick={() => compiledtranshRedirect()}
                        >
                          Trashed Exercises
                        </Button>
                      </Col>
                      <Col md={4}>
                        {" "}
                        <Stack spacing={2}>
                          <Pagination
                            count={comCountPage}
                            page={comPage}
                            onChange={handleComPaginationChange}
                            size="large"
                          />
                        </Stack>
                      </Col>
                      <Col md={4}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <label>Rows per page:</label>
                          <Select
                            value={comPerPage}
                            onChange={handleComPageSizeChange}
                          >
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={500}>500</MenuItem>
                          </Select>
                        </Stack>
                      </Col>
                    </Row>
                  </div>
                </div>
              </section>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ListExercise;
